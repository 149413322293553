import revive_payload_client_4sVQNw7RlN from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import babylon_C7T9RXwCjK from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/plugins/babylon.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  babylon_C7T9RXwCjK
]