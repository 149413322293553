import { default as _91id_935M5c4IUHcQMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/company/[id].vue?macro=true";
import { default as companyJeifdrFDsFMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/company.vue?macro=true";
import { default as indexFAoO5WNPn9Meta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/index.vue?macro=true";
import { default as _91id_930tnwDTg2ZrMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/product/[id].vue?macro=true";
import { default as products5mKS3qwcdkMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/products.vue?macro=true";
import { default as profile_45contentxwZeQUzFylMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/profile-content.vue?macro=true";
import { default as profilep44NYkF6DsMeta } from "C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/profile.vue?macro=true";
export default [
  {
    name: "company",
    path: "/company",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: "company-id",
    path: ":id()",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/company/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "product-id",
    path: "/product/:id()",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "profile-content",
    path: "/profile-content",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/profile-content.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("C:/dev/GITHUB/Projets Affordance/Passeport produit/jeu-passeport-produit/pages/profile.vue").then(m => m.default || m)
  }
]