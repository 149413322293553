<template>
	<div>
		<transition name="fade-drop">
			<div v-if="isDarkHeaderPage" key="dark" class="w-[100vw] fixed bg-dark h-[106px]"></div>
			<div v-else key="light" class="w-[100vw] fixed bg-light-gray h-[108px]"></div>
		</transition>
		
		<div class="font-sans bg-light-gray">
			<div class="max-w-screen-sm mx-auto h-[calc(100dvh-58px)] relative overflow-hidden">
				<NuxtPage />
			</div>
			<UiFooter/>
		</div>
	</div>
</template>

<script lang="ts" setup>
const route = useRoute()

const isDarkHeaderPage = computed(() => {
	const isProfileContentPage = route.path === '/profile-content'
	const isDarkCategory = route.query.category === 'Purchased items' || route.query.category === 'My recommendations'

	return route.path.startsWith('/products') 
		|| route.path.startsWith('/company') 
		|| (isProfileContentPage && isDarkCategory)
})
</script>

<style lang="stylus">
.page-enter-active, .page-leave-active 
	position absolute 
	width 100%
	height 100%
	overflow hidden
	transition transform 0.35s ease

.page-enter-from 
	transform translateX(100%) 

.page-leave-to 
	transform translateX(-100%)

.fade-drop-enter-active, .fade-drop-leave-active
	transition transform 0.35s ease, opacity 0.5s ease 
	transform-origin top

.fade-drop-enter-from
	opacity 0
	transform translateY(-100%)

.fade-drop-leave-to
	opacity 0
	transform translateY(-100%)
	transition transform 0.35s ease, opacity 0.5s ease 0.15s

</style>
