<template>
	<div class="px-4" data-id="footer">
		<hr class="hr">
		<div class="flex justify-between px-4 py-2 max-w-screen-sm mx-auto">
			<NuxtLink to="/">
				<div class="logo cursor-pointer border border-dark rounded-full" data-id="home-btn"></div>
			</NuxtLink>
			<div v-if="!isHomePage"
				class="bg-[url('assets/img/icons/back.svg')] bg-[length:38px] bg-no-repeat bg-center size-[40px] cursor-pointer"
				@click="goBack">
			</div>
			<NuxtLink to="/Profile">
				<div class="bg-[url('assets/img/icons/profile.svg')] bg-[length:38px] bg-no-repeat bg-center size-[40px] cursor-pointer" data-id="profile-btn"></div>
			</NuxtLink>
		</div>
	</div>
</template>

<script lang="ts" setup>

const route = useRoute()
const router = useRouter()

const isHomePage = computed(() => route.path === '/')

const goBack = () => {
	const currentPath = route.path

	const path = [
		{
			from: '/products',
			backTo: [
				{
					when: () => true,
					path: '/'
				}
			]
		},
		{
			from: '/product',
			backTo: [
				{
					when: () => route?.query?.companyId,
					path: `/company/${route?.query?.companyId}`
				},
				{
					when: () => true,
					path: '/products'
				}
			]
		},
		{
			from: '/company',
			backTo: [
				{
					when: () => true,
					path: '/products'
				}
			]
		},

	]?.find(m => currentPath?.startsWith?.(m.from))?.backTo?.find?.(m => m.when())?.path

	if(path) router?.push({ path, query: route?.query })
	else router.back()
}
</script>

<style></style>
